.place-control {
  display: flex;
  flex-grow: 1;
  background-color: #13191f;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  flex-direction: column;
}
.place-info {
	display: flex;
  width: 100%;
  flex-direction: column;
}
.search-container {
	display: flex;
	flex-grow: 1;
  align-items: center;
}
.search-place-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  &.left-border {
    border-left: 1px solid #4c4c4c;
  }
}
.search-places-wrap {
  display: flex;
  width: calc(100% - 53px);
  background-color: #080808;
  border-radius: 5px;
  padding: 20px;
  margin-top: 5px;
}
.no-data {
  color: #4c4c4c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.error-msg {
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgb(255 0 0 / 13%);
  padding: 10px;
  border-radius: 3px;
  border: 2px solid #f53e3e;
  strong {
    color: #c24e4e;
    padding: 0;
    margin: 0;
    font-size: 14px;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    svg {
      margin-right: 5px;
    }
  }
}
.react-json-view {
  padding: 10px;
  border-radius: 5px;
}
.loading-data {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 5px;
    font-size: 14px;
    color: #fff;
    strong {
      color: #00ffc0;
    }
  }
}
.test-id-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  background-color: #0f0f0f;
  padding: 10px;
  border-radius: 5px;
}
.testing-info-quick-copy-area {
  display: flex;
  flex-direction: column;
  padding: 22px;
  flex-grow: 1;
  margin: 10px;
  margin-top: 0;
  border-radius: 10px;
  background-color: #1e201f;
}
.section-title {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  &.testing svg {
    color: #00fff7;
  }
  &.places svg {
    color: #ff3b00;
  }
  &.users svg {
    color: #00b1ff;
  }
  &.group svg {
    color: green;
  }
  svg {
    margin-right: 5px;
  }
}
button.clickable-copy-btn {
  background-color: #ddd;
  border: 2px solid #00d1c1;
  background-color: rgb(0 206 171 / 7%);
  font-weight: bolder;
  color: #00d1c1;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: none;
  margin-left: 20px;
  svg {
    margin-left: 10px;
  }
}
.app-title {
  width: 100%;
  display: flex;
  padding: 10px;
  border-bottom: 2px solid #24417a;
  margin-bottom: 10px;
  &.hazardview {
    svg {
      width: 160px;
      height: auto;
    }
  }
}
.mobile-application-area {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
}
.hazardview-apps {
  display: flex;
  width: 100%;
}
.app-block {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 250px;
  padding-top: 20px;
  justify-content: center;
  text-align: center;
  background-color: #0b0b0b;
  border-radius: 10px;
  &:not(:nth-child(1)) {
    margin-left: 10px;
  }
  a {
    color: #3DDC84;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.4;
    }
  }
  span {
    font-size: 12px;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #aaa;
    line-height: 12px;
  }
  strong {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
  }
}
.app-row {
  background-color: #000;
  padding: 10px;
  border-radius: 10px;
  &.hazardview {
    background-color: #111c29;
  }
}
button.app-edit-btn {
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.dialog-title-with-icon {
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}
.ios-endpoint {
  display: flex;
  align-items: center;
  margin-top: 10px;
  strong {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border: 2px solid #000;
    background-color: rgba(0,0,0,0.5);
    padding: 10px;
    width: 100%;
    color: #b0fffd;
  }
}