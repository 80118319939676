.warning-alert {
  display: flex;
  background-color: #120901;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid #401c05;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  p {
    display: flex;
    span {
      color: #e48800;
      font-weight: bold;
      width: 100%;
      font-size: 15px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  i {
    margin: 10px;
    font-size: 10px;
    color: #ead7b7;
    display: flex;
    max-width: 250px;
    align-items: center;
    display: flex;
  }
}

.action-top-bar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
